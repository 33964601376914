<template>
  <section class="home">
    <WideScreen v-if="isWelcomeCarousel" />
    <SmallScreen v-else />
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    WideScreen: () => import("./Home/WideScreen"),
    SmallScreen: () => import("./Home/SmallScreen"),
  },
  computed: {
    ...mapState(["events"]),
    isHomeRoute() {
      return this.$route.path === "/" ? true : false;
    },
    isWelcomeCarousel() {
      if (this.isHomeRoute) return this.events.width > 1200 ? true : false;
      return false;
    },
  },
};
</script>

<style scoped>
.home{
  overflow: hidden;
}
</style>